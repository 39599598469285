import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createI18n } from 'vue-i18n'
import localeZH from 'element-plus/dist/locale/zh-cn.mjs'
import localeEN from 'element-plus/dist/locale/en.mjs'
import messages from '../../utils/i18n'

import Vant from 'vant';
import 'vant/lib/index.css';
import '@vant/touch-emulator';

const i18n = createI18n({
  locale: localeZH.name,
  fallbackLocale: localeEN.name,
  messages,
})

export default (app) => {
  app.use(Vant)
  app.use(ElementPlus, { locale: localeZH })
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  app.use(i18n)
}
