import cache from '@/assets/js/cache.js' 

const saveAuth = (username, token, userInfo) => {
    if(username) {
        cache.put('username', username)
    }
    if(token) {
        cache.put('token', token)
    }
    if(userInfo && (typeof userInfo === 'object') && userInfo !== null) {
        cache.put('userInfo', JSON.stringify(userInfo))
    }
}

const removeAuth = () => {
    cache.remove('username')
    cache.remove('token')
    cache.remove('userInfo')
}

const removeToken = () => {
    cache.remove('token')
}

const getToken = () => {
    return cache.get('token')
}

const getUsername = () => {
    return cache.get('username')
}

const getUserInfo = () => {
    let userInfoJS = cache.get('userInfo')
    try {
        return JSON.parse(userInfoJS)
    } catch(e) {
        return null
    }
}


export default {
    saveAuth,
    removeAuth,
    removeToken,
    getToken,
    getUsername,
    getUserInfo
}