import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './assets/js/element'
import './assets/css/iconfont.css'
import './assets/js/resizeObserve.js'
import cache from '@/assets/js/cache.js' 
import auth from '@/utils/auth.js'
import 'amfe-flexible' // rem

const app = createApp(App)
installElementPlus(app)
app.config.globalProperties.$cache = cache
app.config.globalProperties.$auth = auth
app
    .use(store)
    .use(router)
    .mount('#app')