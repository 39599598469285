<template>
    <router-view />
</template>

<script>
import pkg from '../package.json'
export default {
    beforeCreate() {
        const version = pkg.version;
        console.log(`版本号version：${version}`)
        this.$store.commit('saveVersion', version)
    },
    created() {
    }
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
</style>
